import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Modal,
  NoticeBar,
  Toast,
  Dialog,
  SpinLoading,
  Divider,
  Form,
  TextArea,
  Input,
  List,
  CapsuleTabs,
  Image,
  Collapse,
} from 'antd-mobile'
import { connect } from 'react-redux'
import { setUserInfoCache } from '@/redux/actions/userMap'
import { PubSub } from 'pubsub-js'
import { saveBottleApi, getBottleApi, startChatApi, reportBottleApi, pickOnlineUserApi } from '@/services/chat'
import { getRobotsApi } from '@/services/gpt'

import { useNavigate } from 'react-router-dom'
import { isMobile } from '@/utils/common'
import cookie from 'react-cookies'

import './Discover.css'

function Discover(props) {
  const { style, user, openChat, broadcast } = props

  const [robots, setRobots] = useState([])

  useEffect(() => {
    async function fetchData() {
      const { data, code, msg } = await getRobotsApi()
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg })
        return
      }
      setRobots(data)
    }
    fetchData()
  }, [])

  const handleStartChat = (robotUid) => {
    return async () => {
      // 创建聊天
      const { code, data, msg } = await startChatApi('gpt', robotUid)
      if (code !== 200) {
        Toast.show({ content: msg, icon: 'fail' })
        return
      }
      if (!data) return
      openChat(data)
    }
  }

  return (
    <div className="discover-panel-wrapper" style={style}>
      {broadcast && broadcast['discover'] && <NoticeBar content={broadcast['discover']} color="alert" />}
      {/* <Divider contentPosition="left" style={{ margin: '10px 0px 4px 0px' }}>
        虚拟人物
      </Divider> */}
      <List>
        {robots.map((robot) => (
          <List.Item
            className="dialog-item"
            clickable
            arrow={false}
            onClick={handleStartChat(robot.id)}
            style={{ padding: '0px 0px 0px 12px', textDecoration: 'none' }}
            key={robot.nickname}
            prefix={
              <Image alt="img" src={robot.avatar} style={{ borderRadius: 20 }} fit="cover" width={54} height={54} />
            }
            description={robot.description}>
            {robot.nickname}
          </List.Item>
        ))}
      </List>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

function mapDispatchProps(dispatch) {
  return {
    setUserInfoCache: (user) => dispatch(setUserInfoCache(user)),
  }
}

export default connect(mapStateToProps, mapDispatchProps)(Discover)
