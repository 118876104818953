import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Modal,
  NoticeBar,
  Toast,
  Dialog,
  Popup,
  Divider,
  Form,
  TextArea,
  Input,
  Swiper,
  CapsuleTabs,
  Image,
  Collapse,
} from 'antd-mobile'
import { connect } from 'react-redux'
import MatchSetting from '@/components/match-panel/MatchSetting'
import { setUserInfoCache } from '@/redux/actions/userMap'
import { PubSub } from 'pubsub-js'
import {
  saveBottleApi,
  getBottleApi,
  startChatApi,
  reportBottleApi,
  pickOnlineUserApi,
  getFindlostListApi,
  saveFindlostApi,
  confirmFindlostApi,
  checkSaveFindlostApi,
} from '@/services/chat'
import copy from 'copy-to-clipboard'
import UserProfile from '@/components/user-profile/UserProfile'
import LoadingContent from '@/components/common/LoadingContent'
import { getAdApi } from '@/services/user'
import { useNavigate } from 'react-router-dom'
import { isMobile } from '@/utils/common'
import { getActivePayUrlApi, getActivePayStatusApi } from '@/services/active'
import { wsQuitMatch, wsStartMatch } from '@/services/websocket'
import cookie from 'react-cookies'
import { updateUserApi } from '@/services/user'
import { appWidth } from '@/const/config'
import './MatchPanel.css'

function MatchPanel(props) {
  const { style, user, openChat, broadcast } = props

  const [selectWard, setSelectWard] = React.useState('default')
  const [userCount, setUserCount] = useState({ todayCount: 0, onlineCount: 0 })
  const [activeVisible, setActiveVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const [showPickOnline, setShowPickOnline] = useState(false)
  const [pickOnlineUser, setPickOnlineUser] = useState({})
  const [pouLoading, setPouLoading] = useState(false)
  const [getBottleLoading, setGetBottleLoading] = useState(false)

  const [saveBottleVisible, setSaveBottleVisible] = useState(false)
  const [getBottleVisible, setGetBottleVisible] = useState(false)
  const [getBottle, setGetBottle] = useState({})

  const [matchSettingVisible, setMatchSettingVisible] = useState(false)
  const [wardSelectVisible, setWardSelectVisible] = useState(false)

  const [ad, setAd] = useState(null)
  const [bottleForm] = Form.useForm()
  const matchSettingRef = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    let wsBus = null
    if (user.id) {
      wsBus = PubSub.subscribe('message', (topic, wsMsg) => {
        if (wsMsg.func === 'match_result') {
          handleMatchResult(wsMsg)
        } else if (wsMsg.func === 'update_user_count') {
          handleUpdateUserCount(wsMsg)
        } else if (wsMsg.func === 'start_match_manual') {
          handleMatch()
        } else if (wsMsg.func === 'need_active') {
          showNeedActive(wsMsg)
        }
      })
    }
    setSelectWard(user.ward || 'default')
    return () => {
      if (wsBus) PubSub.unsubscribe(wsBus)
    }
  }, [user])

  function handleClose() {
    wsQuitMatch(user.id)
    setVisible(false)
    Toast.show({
      position: 'top',
      content: '已退出匹配',
    })
  }

  function showNeedActive(wsMsg) {
    setTimeout(() => {
      setVisible(false)
      Toast.show({ content: wsMsg.data.msg, duration: 2000, position: 'top' })
      // setActiveVisible(true)
    }, 300)
  }

  function handleMatchResult(wsMsg) {
    setVisible(false)
    Toast.show({ position: 'top', content: wsMsg.msg, duration: 3000 })
  }

  function handleUpdateUserCount(wsMsg) {
    const { today_count, online_count } = wsMsg.data
    setUserCount({ todayCount: today_count, onlineCount: online_count })
  }

  function hasCompleteUserInfo() {
    // 判断用户信息完整度，太少了给提示
    if (user['gender'] === 'keep_secret') {
      Toast.show({ icon: 'fail', content: '请先在设置页选择性别，并完善个人信息' })
      return
    }
    let hasValueCount = 0
    const keys = ['avatar', 'gender', 'age', 'location']
    keys.forEach((key) => {
      if (user[key] !== null) hasValueCount = hasValueCount + 1
    })
    if (hasValueCount < 2) {
      Toast.show({ icon: 'fail', content: '用户信息过少，请先前往设置页完善个人信息' })
      return false
    }
    return true
  }

  function getOldSetting() {
    const tmp = cookie.load('matchSetting')
    if (tmp) {
      return {
        gender: tmp.gender,
        position: tmp.position,
        min_age: tmp.minAge,
        max_age: tmp.maxAge,
      }
    }
    return {
      gender: 'any',
      position: 'any',
      min_age: 18,
      max_age: 70,
    }
  }

  const lastMatchTs = useRef(null)
  async function handleMatch() {
    if (!hasCompleteUserInfo()) {
      return
    }
    const nowTs = new Date().getTime()
    if (lastMatchTs.current && nowTs - lastMatchTs.current < 2000) {
      Toast.show({ content: '随机匹配每次需间隔2秒' })
      return
    }
    lastMatchTs.current = nowTs

    let settings = getOldSetting()
    if (matchSettingRef.current) {
      settings = {
        gender: matchSettingRef.current.selectGender,
        position: matchSettingRef.current.selectPosition,
        min_age: matchSettingRef.current.minAge,
        max_age: matchSettingRef.current.maxAge,
      }
    }
    wsStartMatch(user.id, settings)
    setVisible(true)
    // 设置超时时间
    setTimeout(() => {
      setVisible(false)
    }, 300000)
  }

  async function handlePickOnline() {
    if (!hasCompleteUserInfo()) {
      return
    }
    let settings = getOldSetting()
    if (matchSettingRef.current) {
      settings = {
        gender: matchSettingRef.current.selectGender,
        position: matchSettingRef.current.selectPosition,
        min_age: matchSettingRef.current.minAge,
        max_age: matchSettingRef.current.maxAge,
      }
    }
    setShowPickOnline(false)
    setPouLoading(true)
    const { code, msg, data } = await pickOnlineUserApi(settings)
    setPouLoading(false)
    if (code === 422) {
      Toast.show({ content: msg, duration: 2000, position: 'top' })
      setActiveVisible(true)
      return
    }
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg, duration: 2000 })
      return
    }
    // 更新用户缓存
    Toast.show({ content: msg, position: 'top' })
    setUserInfoCache(data)
    setPickOnlineUser(data)
    setShowPickOnline(true)
  }

  function handleChatFromPickOnline(callmeToken) {
    return async () => {
      setShowPickOnline(false)
      const { code, data, msg } = await startChatApi('pick_online', callmeToken)
      if (code !== 200) {
        Toast.show({ content: msg })
        return
      }
      if (!data) return
      openChat(data)
    }
  }

  async function handleSaveBottle() {
    if (!hasCompleteUserInfo()) {
      return
    }
    const content = bottleForm.getFieldValue('content')
    const maxPv = bottleForm.getFieldValue('maxPv')
    const { code, msg } = await saveBottleApi(content, Number(maxPv))
    if (code === 422) {
      setSaveBottleVisible(false)
      Toast.show({ content: msg, duration: 2000, position: 'top' })
      setActiveVisible(true)
      return
    }
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg, duration: 2000 })
    } else {
      Toast.show({ content: msg, position: 'top' })
      setSaveBottleVisible(false)
    }
  }

  async function handleGetBottle() {
    if (!hasCompleteUserInfo()) {
      return
    }
    let settings = getOldSetting()
    if (matchSettingRef.current) {
      settings = {
        gender: matchSettingRef.current.selectGender,
        position: matchSettingRef.current.selectPosition,
        min_age: matchSettingRef.current.minAge,
        max_age: matchSettingRef.current.maxAge,
      }
    }
    setGetBottleLoading(true)
    const { code, data, msg } = await getBottleApi(settings)
    setGetBottleLoading(false)
    if (code === 422) {
      Toast.show({ content: msg, duration: 2000, position: 'top' })
      setActiveVisible(true)
      return
    }
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg, duration: 2000 })
      return
    }
    let bottleData = data.bottle
    bottleData.nickname = data.user_info && data.user_info.nickname
    bottleData.gender = data.user_info && data.user_info.gender
    setGetBottle(bottleData)
    setGetBottleVisible(true)
    Toast.show({ content: '成功捞到，' + msg, position: 'top' })
  }

  async function handleReportBottle() {
    const result = await Dialog.confirm({
      title: '提示',
      content: '确认举报吗?',
    })
    if (result) {
      const { code } = await reportBottleApi(getBottle.id)
      if (code !== 200) return
      setGetBottleVisible(false)
      Toast.show({ content: '感谢反馈，后台审核确认后将扣除该用户的魅力值！' })
    }
  }

  async function handleChatFromBottle() {
    // 更新用户信息
    const { code, data } = await startChatApi('bottle', getBottle.id)
    if (code !== 200) return
    if (!data) return
    setGetBottleVisible(false)
    openChat(data)
  }

  const wardMap = {
    default: '畅聊',
    green: '清流',
  }
  const wardRef = React.useRef(null)
  async function handleAction(e) {
    if (e.key === 'ok') {
      if (wardRef.current && wardRef.current !== selectWard) {
        const { code, msg } = await updateUserApi({ ward: wardRef.current })
        if (code !== 200) {
          Toast.show({ icon: 'fail', content: msg })
          return
        }
        setSelectWard(wardRef.current)
        Toast.show({ icon: 'success', content: '修改分区成功' })
      }
    }
    setWardSelectVisible(false)
  }

  // 寻人公告
  const [lostNotices, setLostNotices] = useState([])
  useEffect(() => {
    async function fetchData() {
      const { code, data, msg } = await getFindlostListApi()
      if (code !== 200) {
        Toast.show({ icon: 'fail', content: msg, duration: 2000 })
        return
      }
      setLostNotices(data)
      if (data && data.length) {
        lostCurrIndexRef.current = 0
      }
    }
    fetchData()
  }, [])
  // 发布的dialog
  const [saveLostVisible, setSaveLostVisible] = useState(false)
  let height = '95%'
  if (props.height) {
    height = props.height
  }
  let popupStyle = { maxHeight: 'var(--app-height)', minHeight: '50%', height: height }
  if (!isMobile()) {
    popupStyle = {
      left: `calc(50% - ${appWidth} / 2)`,
      width: appWidth,
      height: height,
    }
  }

  let saveLostFormInit = {}
  if (localStorage.getItem('saveLostForm')) {
    saveLostFormInit = JSON.parse(localStorage.getItem('saveLostForm'))
  }
  const [saveLostForm] = Form.useForm()

  async function handleClickSaveLost() {
    const { code, msg } = await checkSaveFindlostApi()
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg, duration: 2000 })
      return
    }
    setSaveLostVisible(true)
  }
  function handleCancelLost() {
    const title = saveLostForm.getFieldValue('title')
    const content = saveLostForm.getFieldValue('content')
    const question = saveLostForm.getFieldValue('question')
    const answer = saveLostForm.getFieldValue('answer')
    const contack_with = saveLostForm.getFieldValue('contack_with')
    const data = { title, content, question, answer, contack_with }
    localStorage.setItem('saveLostForm', JSON.stringify(data))

    setSaveLostVisible(false)
  }
  async function handleSaveLost() {
    const title = saveLostForm.getFieldValue('title')
    const content = saveLostForm.getFieldValue('content')
    const question = saveLostForm.getFieldValue('question')
    const answer = saveLostForm.getFieldValue('answer')
    const contack_with = saveLostForm.getFieldValue('contack_with')
    const data = { title, content, question, answer, contack_with }
    localStorage.setItem('saveLostForm', JSON.stringify(data))

    const { code, msg } = await saveFindlostApi(title, content, question, answer, contack_with)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg, duration: 2000 })
    } else {
      Toast.show({ content: msg, position: 'top' })
      setSaveLostVisible(false)
    }
  }

  const lostCurrIndexRef = useRef(null)
  const [confirmLostVisible, setConfimLostVisible] = useState(false)
  const [confirmLostForm] = Form.useForm()

  function handleConfirmLostAction(findlostId, contack_with) {
    return async (e) => {
      if (e.key === 'copy') {
        // 复制到剪切板
        copy(contack_with)
        Toast.show('已将联系方式复制到剪切板')
      } else if (e.key === 'startChat') {
        const { code, data, msg } = await startChatApi('findlost', findlostId)
        if (code !== 200) {
          Toast.show({ icon: 'fail', content: msg, duration: 2000 })
          return
        }
        if (!data) return
        openChat(data)
      }
    }
  }
  async function handleConfirmLost() {
    const findlostId = lostNotices[lostCurrIndexRef.current].id
    const myAnswer = confirmLostForm.getFieldValue('myAnswer')
    const { code, msg, data } = await confirmFindlostApi(findlostId, myAnswer)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg, duration: 2000 })
      return
    }
    Toast.show({ content: msg, position: 'top' })
    setConfimLostVisible(false)
    // 展示答案
    Modal.show({
      title: '寻人公告 联系方式',
      closeOnAction: true,
      onAction: handleConfirmLostAction(findlostId, data?.contack_with),
      actions: [
        {
          key: 'startChat',
          text: '开始聊天',
          primary: true,
        },
        {
          key: 'copy',
          text: '复制联系方式',
        },
      ],
      content: (
        <>
          <div style={{ fontSize: 13, color: 'var(--adm-color-weak)', textAlign: 'center', marginBottom: 20 }}>
            <div>联系方式也已通过系统通知发送给您</div>
          </div>
          <Form>
            <Form.Item>
              <Input disabled value={data?.contack_with} />
            </Form.Item>
          </Form>
        </>
      ),
    })
  }

  return (
    <div className="discover-panel-wrapper" style={style}>
      <Dialog
        visible={wardSelectVisible}
        onAction={handleAction}
        actions={[
          [
            {
              key: 'cancel',
              text: '取消',
            },
            {
              key: 'ok',
              text: '确认',
              bold: true,
            },
          ],
        ]}
        title="选择分区"
        content={
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: 14 }}>
              <div>只能匹配到同一分区的用户</div>
            </div>
            <CapsuleTabs
              defaultActiveKey={selectWard}
              onChange={(val) => {
                wardRef.current = val
              }}>
              <CapsuleTabs.Tab title="畅聊" key="default" />
              <CapsuleTabs.Tab title="清流" key="green" />
            </CapsuleTabs>
            <div style={{ color: '#ff5858', fontSize: 14, marginTop: 4 }}>
              <div>若过去3天存在多次违规行为则无法进入清流分区</div>
            </div>
          </div>
        }
        closeOnMaskClick
        onClose={() => {
          setWardSelectVisible(false)
        }}
        bodyStyle={{ opacity: 0.9 }}
      />

      <Dialog
        visible={getBottleLoading}
        content={
          <LoadingContent
            ad={ad}
            visible={getBottleLoading}
            page="meet"
            widget="getBottle-loading-dialog"
            content="捞取中"
          />
        }
        closeOnMaskClick
        onClose={() => {
          setGetBottleLoading(false)
        }}
        bodyStyle={{ opacity: 0.9 }}
      />
      <Dialog
        visible={pouLoading}
        content={
          <LoadingContent
            ad={ad}
            visible={pouLoading}
            page="meet"
            widget="pickOnlineUser-loading-dialog"
            content="寻找中"
          />
        }
        closeOnMaskClick
        onClose={() => {
          setPouLoading(false)
        }}
        bodyStyle={{ opacity: 0.9 }}
      />
      <Dialog
        visible={visible}
        content={<LoadingContent ad={ad} visible={visible} page="meet" widget="meet-loading-dialog" content="匹配中" />}
        closeOnMaskClick
        onClose={handleClose}
        bodyStyle={{ opacity: 0.9 }}
      />
      {broadcast && broadcast['match'] && <NoticeBar content={broadcast['match']} color="alert" />}

      <div style={{ margin: '0px 0px -16px 0px', position: 'relative' }}>
        <Divider contentPosition="left">寻人公告</Divider>
        <div className="setting-wrapper">
          <div className="ward-wrapper">
            <span
              className="notice-btn claim-notice-btn"
              onClick={() => {
                if (lostCurrIndexRef.current == null) {
                  Toast.show({ icon: 'fail', content: '当前无可用的寻人公告' })
                  return
                }
                setConfimLostVisible(true)
              }}>
              认领
            </span>
          </div>
          <div className="notice-btn push-notice-btn" onClick={handleClickSaveLost}>
            发布
          </div>
        </div>
      </div>

      <div className="swiper-wrapper">
        <Swiper
          autoplay={!confirmLostVisible}
          loop
          autoplayInterval="5000"
          style={{ '--height': '100%' }}
          onIndexChange={(i) => {
            lostCurrIndexRef.current = i
          }}>
          {lostNotices.length ? (
            lostNotices.map((item, index) => (
              <Swiper.Item key={index}>
                <div className="lost-item-wrapper">
                  <div className="lost-item-title">{item.title}</div>
                  <div className="lost-item-content">{item.content}</div>
                </div>
              </Swiper.Item>
            ))
          ) : (
            <Swiper.Item>
              <div className="lost-item-wrapper">
                <div className="nodata">暂无寻人公告</div>
              </div>
            </Swiper.Item>
          )}
        </Swiper>
      </div>

      <div style={{ margin: '30px 0px -16px 0px', position: 'relative' }}>
        <Divider contentPosition="left">遇见陌生人</Divider>
        <div className="setting-wrapper">
          <div className="ward-wrapper">
            <span
              className="ward-btn"
              onClick={() => {
                setWardSelectVisible(true)
              }}>
              {wardMap[selectWard] + '分区'}
            </span>
          </div>
          <div
            className="setting-btn"
            onClick={() => {
              setMatchSettingVisible(true)
            }}>
            陌生人设置
          </div>
        </div>
      </div>

      <div className="match-wrapper">
        <div className="line1">
          <div className="bottle-wrapper">
            <div
              className="bottle bottle1"
              onClick={() => {
                if (!hasCompleteUserInfo()) {
                  return
                }
                bottleForm.resetFields()
                setSaveBottleVisible(true)
              }}>
              扔瓶子
            </div>
            <div className="bottle bottle2" onClick={handleGetBottle}>
              捞瓶子
            </div>
          </div>
          <div className="right-wrapper">
            <div className="mattch-btn" onClick={handlePickOnline}>
              <div className="t1">
                捞个在线
                {/* <span style={{ fontSize: 14 }}> （剩余 3 次）</span> */}
              </div>
              {/* <div className="t2">根据你们的性格兴趣，精心准备一场邂逅</div> */}
              <div className="t2">随机捞一个在线的用户</div>
            </div>
            <div className="meet-btn" onClick={handleMatch}>
              <div className="t1">随机匹配</div>
              <div className="t2">匹配一个正在匹配中的用户</div>
            </div>
          </div>
        </div>
      </div>

      {user.is_vip && (
        <div style={{ textAlign: 'center', marginTop: 20, color: 'var(--adm-color-weak)' }}>
          今日到访：{userCount.todayCount}人，当前在线：
          <span>
            <span style={{ color: '#02a102' }}>{userCount.onlineCount}</span>人
          </span>
        </div>
      )}

      <Dialog
        visible={showPickOnline}
        content={<UserProfile user={pickOnlineUser} hideRemark={true} showOpt={false} />}
        closeOnMaskClick
        onClose={() => {
          setShowPickOnline(false)
        }}
        actions={[
          [
            {
              key: 'next',
              text: '下一个',
              style: { color: 'var(--adm-color-warning)' },
              bold: true,
              onClick: handlePickOnline,
            },
            {
              key: 'chat',
              text: '聊聊看',
              bold: true,
              onClick: handleChatFromPickOnline(pickOnlineUser.id),
            },
          ],
        ]}
      />

      <Dialog
        visible={getBottleVisible}
        content={
          <>
            <TextArea style={{ border: '1px solid var(--theme-window-bgcolor)' }} rows={6} value={getBottle.content} />
            <div style={{ textAlign: 'right', color: 'var(--adm-color-weak)', margin: '10px 0 5px 2px' }}>
              <div>
                <span>{getBottle.nickname}</span>
                <span style={{ marginLeft: 20 }}>
                  {getBottle.gender === 'male' ? '男' : getBottle.gender === 'female' ? '女' : '保密'}
                </span>
              </div>
              <div style={{}}>
                <span>{new Date(getBottle.create_ts).format('MM月dd日 hh:mm')}</span>
              </div>
            </div>
          </>
        }
        closeOnMaskClick
        title="捞瓶子"
        actions={[
          [
            {
              key: 'chat',
              text: '聊聊看',
              bold: true,
              onClick: handleChatFromBottle,
            },
            {
              key: 'report',
              text: '举报',
              danger: true,
              onClick: handleReportBottle,
            },
          ],
        ]}
        onClose={() => {
          setGetBottleVisible(false)
        }}
      />
      <Modal
        visible={matchSettingVisible}
        closeOnAction
        closeOnMaskClick
        title="陌生人设置"
        onClose={() => {
          setMatchSettingVisible(false)
        }}
        content={
          <>
            <div style={{ padding: '10px 0px 10px 0px' }}>
              <div style={{ marginBottom: 6 }}>
                <div style={{ fontSize: 10, color: 'var(--adm-color-weak)', marginBottom: 2, textAlign: 'center' }}>
                  {!user.is_vip && <span>魅力值大于200可修改，仅在 捞个在线、捞瓶子 中生效</span>}
                </div>
                <div style={{ fontSize: 10, color: 'var(--adm-color-weak)', textAlign: 'center' }}>
                  {!user.is_vip && user.likes >= 200 && (
                    <span>修改陌生人设置，使用捞个在线、捞瓶子，每次将消耗5魅力值</span>
                  )}
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                <MatchSetting user={user} ref={matchSettingRef} />
              </div>
            </div>
          </>
        }
      />

      <Modal
        visible={saveBottleVisible}
        content={
          <>
            <div style={{ fontSize: 10, color: 'red', marginBottom: 10, textAlign: 'center' }}>
              <div>内容将受到人工审核，违规内容一次扣除5魅力值</div>
            </div>

            <Form
              form={bottleForm}
              onFinish={handleSaveBottle}
              initialValues={{ maxPv: 20 }}
              footer={
                <Button block type="submit" color="primary" size="large">
                  扔出
                </Button>
              }>
              <Form.Item
                name="content"
                rules={[{ min: 3, max: 100, required: true, message: '内容长度需在3-100之间' }]}>
                {/* <TextArea showCount rows={6} placeholder="请输入内容 (若自动审核误识别可联系客服申诉)" /> */}
                <TextArea
                  showCount
                  rows={6}
                  placeholder="分享 故事、段子、音乐、梦想、祝福、经历 更容易获得别人的青睐哦~"
                />
              </Form.Item>

              <Form.Item
                name="maxPv"
                label="最大被捞到次数"
                rules={[
                  {
                    max: 20,
                    min: 1,
                    type: 'number',
                    message: '请输入1-20之间的数字',
                    transform: (value) => {
                      return Number(value)
                    },
                  },
                ]}>
                <Input placeholder="请输入数字" />
              </Form.Item>
            </Form>
          </>
        }
        closeOnAction
        closeOnMaskClick
        title="扔瓶子"
        onClose={() => {
          setSaveBottleVisible(false)
        }}
      />

      <Popup
        visible={saveLostVisible}
        bodyStyle={popupStyle}
        onClose={() => {
          setSaveLostVisible(false)
        }}>
        <div className="save-lost-title">
          <div>发布公告</div>
          <div style={{ fontSize: 10, color: 'red', textAlign: 'center', fontWeight: 'normal' }}>
            <div style={{ color: 'red' }}>发布成功将消耗30魅力值，有效期3天。仅能被认领成功一次</div>
            <div style={{ color: 'red' }}>违规内容，无意义的内容 审核不通过将扣魅力值</div>
          </div>
        </div>

        <Form
          form={saveLostForm}
          initialValues={saveLostFormInit}
          onFinish={handleSaveLost}
          footer={
            <div style={{ display: 'flex', gap: 20, justifyContent: 'center' }}>
              <Button style={{ width: 200 }} onClick={handleCancelLost}>
                取消
              </Button>
              <Button style={{ width: 200 }} type="submit" color="primary">
                提交
              </Button>
            </div>
          }>
          <Form.Item
            label="标题"
            name="title"
            rules={[{ min: 4, max: 20, required: true, message: '内容长度需在4-20之间' }]}>
            <TextArea rows={1} placeholder="请输入引人注意的关键信息" />
          </Form.Item>
          <Form.Item
            label="内容"
            name="content"
            rules={[{ min: 3, max: 100, required: true, message: '内容长度需在3-100之间' }]}>
            <TextArea rows={4} placeholder="请输入更多的补充信息。非特定的找人需求请使用扔瓶子" />
          </Form.Item>
          <Form.Item
            label="问题"
            name="question"
            rules={[{ min: 4, max: 20, required: true, message: '内容长度需在4-20之间' }]}>
            <TextArea rows={2} placeholder="请输入认领该公告时的问题" />
          </Form.Item>
          <Form.Item
            label="答案"
            name="answer"
            rules={[{ min: 2, max: 20, required: true, message: '内容长度需在4-20之间' }]}>
            <TextArea rows={1} placeholder="请输入上面问题的答案" />
          </Form.Item>
          <Form.Item
            label="联系方式"
            name="contack_with"
            rules={[{ min: 3, required: true, message: '内容长度需在3-40之间' }]}>
            <TextArea rows={1} placeholder="请输入问题回答通过后 展示的联系方式" />
          </Form.Item>
        </Form>
      </Popup>

      <Modal
        title="认领公告"
        closeOnAction
        closeOnMaskClick
        onClose={() => {
          setConfimLostVisible(false)
        }}
        visible={confirmLostVisible}
        content={
          <>
            <div>
              <div className="lost-item">
                <span className="lost-label">标题：</span>
                <span className="lost-value">{lostNotices[lostCurrIndexRef.current]?.title}</span>
              </div>
              <div className="lost-item">
                <span className="lost-label">内容：</span>
                <span className="lost-value">{lostNotices[lostCurrIndexRef.current]?.content}</span>
              </div>
              <div className="lost-item lost-question">
                <span className="lost-label">问题：</span>
                <span className="lost-value">{lostNotices[lostCurrIndexRef.current]?.question}</span>
              </div>
            </div>
            <Form
              form={confirmLostForm}
              onFinish={handleConfirmLost}
              footer={
                <Button block type="submit" color="primary" size="large">
                  提交答案
                </Button>
              }>
              <Form.Item
                label="答案"
                name="myAnswer"
                rules={[{ min: 1, max: 20, required: true, message: '内容长度需在1-20之间' }]}>
                <TextArea rows={2} placeholder="请输入上面问题的答案" />
              </Form.Item>
            </Form>
          </>
        }
      />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

function mapDispatchProps(dispatch) {
  return {
    setUserInfoCache: (user) => dispatch(setUserInfoCache(user)),
  }
}

export default connect(mapStateToProps, mapDispatchProps)(MatchPanel)
